import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Packages } from '../components/composites/packages';

import { Hero, ImageClipping } from '../components/containers/hero';
import { ImagePosition, ImageShape, ImageSplit } from '../components/containers/image-split';
import { Panel } from '../components/containers/panel';
import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { Style as ButtonStyle, Type as ButtonType } from '../components/elements/button';
import { TextButton } from '../components/elements/text-button';
import { Site } from '../components/shells/site';
import { Heading, Level as HeadingLevel, Level } from '../components/typography/heading';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo
                title={'Leefstijlcoaching in regio Maastricht'}
                description={
                    'Ben jij klaar voor een blijvende, gezondere leefstijl? Dan is leefstijlcoaching wat je zoekt. Ik ben afgestudeerd als geaccrediteerd leefstijlcoach en bied individuele coaching aan in regio Maastricht.'
                }
            />

            <Hero
                image={<StaticImage src={'../../assets/images/hero-coaching-2.jpg'} alt={''} />}
                imageClipping={ImageClipping.VerticalBlob}
                pageTitle={'Leefstijlcoaching in regio Maastricht'}
                pageText={'Ben jij klaar voor een blijvende, gezondere leefstijl? Dan is leefstijlcoaching wat je zoekt.'}
            />

            <Section style={SectionStyle.Light}>
                <div className={'grid md:grid-cols-2 gap-12 mt-8'}>
                    <div className={'py-8'}>
                        <Heading level={Level.Text}>Wat doet een leefstijlcoach?</Heading>
                        <div className={'prose'}>
                            <p>
                                Een leefstijlcoach begeleidt mensen stap voor stap naar een <strong>gezondere leefstijl</strong>. Tijdens
                                een coachingstraject neem ik alle belangrijke leefstijlthema’s mee: voeding, bewegen, slaap en ontspanning.
                                Al deze zaken hangen met elkaar samen. Wil je bijvoorbeeld gewicht verliezen maar heb je last van stress?
                                Dit kan ervoor zorgen dat je minder gezond eet. Dan is het beter om eerst te ontspannen en de stress te
                                reduceren.
                            </p>
                            <p>
                                We kijken samen (dus mét jou) naar <strong>zelfgekozen, haalbare doelen</strong>. Dit zijn vaak kleine
                                stapjes in gedragsverandering waarbij ik je stimuleer om oplossingen te bedenken voor obstakels en
                                belemmeringen. We stellen samen steeds korte tussendoelen die goed te bereiken zijn. Zo blijf je ook lekker
                                gemotiveerd &#x1F60A;
                            </p>
                            <p>
                                Als coach zal ik dus geen dieet voorschrijven en zal ik niet bepalen hoe vaak je moet bewegen. Het is aan
                                jezelf om dit te bepalen, zodat je het makkelijk in jouw eigen leven kunt verweven.
                            </p>
                        </div>
                    </div>
                    <div>
                        <Panel>
                            <Heading level={Level.Second}>Traject</Heading>
                            <div className={'prose'}>
                                <p>
                                    Een coachingstraject beslaat minimaal 4 maanden en start met een{' '}
                                    <strong>vrijblijvend, kosteloos intakegesprek</strong> van 30 minuten. Dit kan via Teams of Google Meet.
                                </p>
                                <p>
                                    Klikt het en is leefstijlcoaching passend? Dan plannen we de rest van de sessies in. Sessies duren 45
                                    minuten en zijn online of combineren we met een wandeling. Afspraken zijn om de 3 weken zodat je genoeg
                                    tijd hebt om aan jouw doelen te werken.
                                </p>
                            </div>
                            <div className={'text-center'}>
                                <TextButton
                                    type={ButtonType.Link}
                                    url={'mailto:vicky@fitfoodforty.nl'}
                                    caption={'Intakegesprek plannen'}
                                    className={'mt-6'}
                                />
                                <div className={'mt-4 text-xs text-gray-700'}>
                                    Of app me via WhatsApp:{' '}
                                    <a
                                        href={'https://wa.me/31641777848'}
                                        target={'_blank'}
                                        title={'App me gerust via WhatsApp!'}
                                        rel={'noreferrer'}
                                        className={'underline'}>
                                        06 4177 7848
                                    </a>{' '}
                                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
                <div className={'mt-10 flex flex-col justify-center items-center gap-20 sm:flex-row'}>
                    <a href={'https://blcn.nl/leefstijlcoach/fitfoodforty'} target={'_blank'}>
                        <StaticImage src={'../../assets/images/blcn-logo.png'} alt={''} className={'w-40'} />
                    </a>
                    <a href={'https://benfit.nl/benfit-volgen'} target={'_blank'}>
                        <StaticImage src={'../../assets/images/benfit-logo.png'} alt={''} className={'w-48'} />
                    </a>
                </div>
            </Section>

            <Section style={SectionStyle.Light}>
                <div className={'grid md:grid-cols-2 gap-12 mt-8'}>
                    <div className={'py-8'}>
                        <Heading level={Level.Text}>BenFit traject</Heading>
                        <div className={'prose'}>
                            <p>Het traject van BenFit is 12 weken met om de week een weegmoment en een gesprek. Je krijgt via de app wekelijks recepten en een boodschappenlijst. Vind je een product niet lekker? Dan kan je zelf een alternatief uitkiezen.</p>
                            <p className={'font-bold'}>Wat komt er precies bij kijken?</p>
                            <ul>
                                <li>Het intakegesprek (ca. 45 minuten): eerste keer wegen, voedingsadvies, uitleg advies, uitleg app</li>
                                <li>Vervolggesprekken/contactmomenten (ca. 15 minuten) met weegmoment. Deze worden bij de intake al meteen vooruit gepland</li>
                                <li>Het evaluatiegesprek (ca. 15 minuten)</li>
                                <li>Follow-up: 1 keer per maand een gesprek (ca. 15 minuten)</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <Panel>
                            <Heading level={Level.Second}>Kosten</Heading>
                            <div className={'prose'}>
                                <p><strong>Traject van 12 weken:</strong> € 275 eenmalig. Betaal je liever per maand? Dan is het € 92 euro per maand, met als voorwaarden dat het traject wordt afgerond.</p>
                                <p><strong>Follow-up:</strong> € 19 per maand</p>
                            </div>
                            <div className={'text-center'}>
                                <TextButton
                                    type={ButtonType.Link}
                                    url={'mailto:vicky@fitfoodforty.nl'}
                                    caption={'Intakegesprek plannen'}
                                    className={'mt-6'}
                                />
                                <div className={'mt-4 text-xs text-gray-700'}>
                                    Of app me via WhatsApp:{' '}
                                    <a
                                        href={'https://wa.me/31641777848'}
                                        target={'_blank'}
                                        title={'App me gerust via WhatsApp!'}
                                        rel={'noreferrer'}
                                        className={'underline'}>
                                        06 4177 7848
                                    </a>{' '}
                                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            </Section>

            <ImageSplit
                imageShape={ImageShape.Rounded}
                imagePosition={ImagePosition.Left}
                content={
                    <>
                        <Heading level={HeadingLevel.Second}>Liever het reguliere traject?</Heading>
                        <div className={'prose'}>
                            <p style={{ marginBottom: 0 }}>
                                De kosten voor het reguliere traject van 4 maanden zijn € 275 incl. btw of € 72,50 per maand. Hiervoor krijg je:
                            </p>
                            <ul style={{ marginTop: 0 }}>
                                <li>
                                    om de <strong>3 weken</strong> een gesprek (dus 4 of 5 gesprekken totaal);
                                </li>
                                <li>
                                    <strong>20% korting</strong> op het abonnement voor het <Link to={'/powerwalking'}>powerwalken</Link> gedurende het leefstijltraject;
                                </li>
                                <li>
                                    elke week <strong>3 gezonde recepten</strong> in de mail.
                                </li>
                            </ul>
                            <p>
                                Ik ben afgestudeerd als{' '}
                                <span className={'inline-block px-2 bg-lemon rounded-md font-bold'}>gediplomeerd leefstijlcoach</span> met
                                een opleiding die is geaccrediteerd door de Beroepsvereniging Leefstijlcoaches (BLCN). Ik bied{' '}
                                <strong>individuele coaching</strong> aan voor iedereen die klaar is voor een blijvende gedragsverandering.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            url={'mailto:vicky@fitfoodforty.nl'}
                            caption={'Interesse of vragen? Mail me!'}
                            className={'mt-6'}
                        />
                        <div className={'mt-4 text-xs text-gray-700'}>
                            Of app me via WhatsApp:{' '}
                            <a
                                href={'https://wa.me/31641777848'}
                                target={'_blank'}
                                title={'App me gerust via WhatsApp!'}
                                rel={'noreferrer'}
                                className={'underline'}>
                                06 4177 7848
                            </a>{' '}
                            <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                        </div>
                    </>
                }
                image={<StaticImage src={'../../assets/images/section-split-vicky-4.jpg'} alt={''} />}
            />

            <Section style={SectionStyle.Gradient}>
                <SectionText>
                    <Heading>Leefstijlcoaching via de werkgever?</Heading>
                    <p>
                        Fitfoodforty is ook partner van{' '}
                        <a href={'https://benvitaal.nl/'} className={'underline'} target={'_blank'}>
                            benVitaal
                        </a>
                        . Dit biedt medewerkers van aangesloten organisaties de mogelijkheid om <strong>voor gereduceerd tarief</strong> aan
                        de slag te gaan met{' '}
                        <a href={'/powerwalking'} className={'underline'}>
                            powerwalking
                        </a>{' '}
                        of leefstijlcoaching.
                    </p>
                    <p className={'mt-4'}>Interesse? Neem contact op en ik informeer je graag over de mogelijkheden</p>
                    <TextButton
                        type={ButtonType.Link}
                        url={'mailto:vicky@fitfoodforty.nl'}
                        caption={'Interesse of vragen? Mail me!'}
                        className={'mt-6'}
                    />
                </SectionText>
            </Section>

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <Heading level={HeadingLevel.Second}>Maak een begin: powerwalking</Heading>
                    <p>
                        Wil je wat meer gaan bewegen, en wil je hier al de eerste stappen in zetten? Dan is <strong>powerwalking</strong>{' '}
                        misschien iets voor jou. Ik geef deze groepslessen op <strong>maandag, dinsdag, vrijdag en zaterdag</strong> in
                        Maastricht.
                    </p>
                    <TextButton
                        type={ButtonType.Link}
                        style={ButtonStyle.Secondary}
                        url={'/powerwalking'}
                        caption={'Meer over powerwalking'}
                        className={'mt-6'}
                    />
                </SectionText>
            </Section>

            <Section>
                <SectionText>
                    <Heading level={HeadingLevel.Second}>Pakketten</Heading>
                    <p>Wil je alles in één keer aanpakken? Kies dan voor een van mijn pakketten. Hiermee krijg je een combinatie van leefstijlcoaching, <a href={'/trainingen'} className={'underline'}>trainingen</a> en <a href={'/powerwalking'} className={'underline'}>powerwalking</a> voor een pakketprijs. Wel zo makkelijk!</p>
                </SectionText>
                <Packages className={'mt-12'} />
            </Section>
        </Site>
    );
};
